import { DataContext } from "DataContainer";
import moment from "moment";
import React, { useContext } from "react";
import {
  // Collapse,
  NavItem,
  NavLink,
  Nav,
  Container,
  Button,
  Row,
  Col,
  TabContent,
  TabPane,
  Collapse,
  Card,
} from "reactstrap";
import { groupBy } from "views/content";

function Agenda() {
  const [activeTab, setActiveTab] = React.useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  const [open, toggleOpen] = React.useState({
    open: -1,
  });
  const { agenda } = useContext(DataContext);
  const dayWiseAgenda = groupBy("day")(agenda);

  function AgendaCard({ data }) {
    const size =
      document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
        ? true
        : false;
    return data.map((d, index) => (
      <Container>
        <Row className="py-4">
          <Col lg={2}>
            <h4 className="text-600 mt-0 text-white">
              <b>
                {moment.utc(d.startAt).format("HH:mm")} - {moment.utc(d.endAt).format("HH:mm")}{" "}
              </b>{" "}
            </h4>
          </Col>
          <Col lg={9} className="text-white">
            <h4 className="text-400 m-0">
              <b>{d.title}</b>
            </h4>
            <p
              className="text-400 text-white pt-4"
              id="agendaDescription"
              style={{ color: "#fff !important" }}
              dangerouslySetInnerHTML={{ __html: d.description }}
            />
          </Col>
          <Col lg={9}></Col>
        </Row>
      </Container>
    ));
  }
  const size =
    document.body.clientWidth >= 1024 || document.documentElement.clientWidth >= 1024
      ? true
      : false;
  return (
    <>
      <div
        style={{
          backgroundColor: "#4f2a76",
          backgroundImage: size && `url(${require("assets/images/Asset2.png")})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
        className="section pb-0"
        data-parallax={true}
      >
        <Container>
          <h1 className="text-300 text-white text-left mb-4">Topics Covered</h1>
          <Row style={{ backgroundColor: "#4f2a76" }}>
            <Col md="12">
              {/* <div className="nav-tabs-navigation text-left mt-3 mb-3">
                <div className="nav-tabs-wrapper">
                  <Nav tabs className="nav-fill">
                    <NavItem>
                      <NavLink
                        className={`text-left p-0 py-3 text-dark ${
                          activeTab === "1" ? "active" : ""
                        }`}
                        onClick={() => {
                          toggle("1");
                        }}
                      >
                        <h4 className="text-400 mt-0 pl-3">
                          <b>DAY 1</b>
                        </h4>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={`text-left p-0 py-3 text-dark ${
                          activeTab === "2" ? "active" : ""
                        }`}
                        onClick={() => {
                          toggle("2");
                        }}
                      >
                        <h4 className="text-400 mt-0 pl-3">
                          <b>DAY 2</b>
                        </h4>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
              </div> */}
            </Col>
            <Col md="12">
              <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                  <Container>
                    <Row>
                      <Col lg={12} className="px-0">
                        <AgendaCard data={dayWiseAgenda[1] || []} />
                      </Col>
                    </Row>
                  </Container>
                </TabPane>
              </TabContent>
              <TabContent activeTab={activeTab}>
                <TabPane tabId="2">
                  <Container>
                    <Row>
                      <Col lg={12} className="px-0">
                        <AgendaCard data={dayWiseAgenda[2] || []} />
                      </Col>
                    </Row>
                  </Container>
                </TabPane>
              </TabContent>
            </Col>
          </Row>
          <Row>
            <Col lg={12} className="py-3 mb-5 text-center">
              <Button
                href="/register"
                className="navbar-item-custom text-400 text-dark rounded-0 "
                style={{
                  background: "#e9ad4a",
                  border: "2px solid #000",
                }}
                size="lg"
              >
                <span
                  style={{
                    color: "#000",
                    textTransform: "none",
                    fontWeight: 700,
                    fontSize: "16px",
                  }}
                  className="text-600"
                >
                  REGISTER NOW <i class="fa fa-arrow-right" />
                </span>
              </Button>
            </Col>
            {/* <Col lg={12} className="pt-3 text-center"></Col> */}
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Agenda;
