import React from "react";
import Navbar from "components/Navbar.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";
import Register from "components/Register";
import Footer from "components/Footer.js";

function RegisterPage({ type, title, subject }) {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title={title} bgColor="#fff" />
      <div className="section profile-content" style={{ padding: 0 }}>
        <Register type={type} subject={subject} />
      </div>

      <Footer />
    </>
  );
}

export default RegisterPage;
