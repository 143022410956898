import React from "react";

import Footer from "components/Footer.js";
import ProfilePageHeader from "components/Headers/ProfilePageHeader.js";

import { Col, Container, Row } from "reactstrap";
import Navbar from "components/Navbar.js";

function PrivacyPolicy() {
  return (
    <>
      <Navbar />
      <ProfilePageHeader title="Privacy Policy" bgColor="#fff" />
      <div className="section profile-content mt-3" style={{ padding: 0 }}>
        <Container>
          <Row>
            <Col md={12}></Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
}

export default PrivacyPolicy;
