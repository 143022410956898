import { apiURL, oldToken, token } from "./constant";

import React, { useEffect, useState } from "react";
import { createContext } from "react";

export const DataContext = createContext({});

export default function DataContainer({ children }) {
  const [data, setData] = useState({
    agenda: [],
    speakers: [],
    sponsors: [],
  });
  const [pastData, setPastData] = useState({
    pastSpeakers: [],
    pastSponsors: [],
    pastAgenda: [],
  });
  async function fetchData() {
    await fetch(`${apiURL}/getEventData`, {
      method: "get",
      headers: new Headers({
        Authorization: oldToken,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setPastData({ pastSpeakers: data.speakers, pastSponsors: data.sponsors });
      })
      .catch((err) => console.log(err));

    await fetch(`${apiURL}/getEventData`, {
      method: "get",
      headers: new Headers({
        Authorization: token,
        "Content-Type": "application/json",
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        setData({ speakers: data.speakers, sponsors: data.sponsors, agenda: data.agenda });
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    fetchData();
  }, []);

  return <DataContext.Provider value={{ ...data, ...pastData }}>{children}</DataContext.Provider>;
}
