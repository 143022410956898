export const chairman = {};

export const newspeakers = [];

export const speakers = [];

export const jury = [];
export const sponsorsnew = [];

export const pastsponsors = [];

export const groupBy = (key) => (array) =>
  array.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});

const groupByType = groupBy("type");
export const sponsorsPage = groupByType(sponsorsnew);

export const news = [];

export const insights = [];
